<template>
  <div class="usercont">
    <div class="user-title">绑定邮箱</div>
    <div v-if="user && user.email" class="info-cell">
      <p>邮箱</p>
      <span>{{ encryptEmail }}</span>
      <span><svg-icon icon-class="edit" @click="isPopupShow = true"></svg-icon></span>
    </div>
    <div v-else class="email-cell">
      <span class="icon"><svg-icon icon-class="warning-orange"></svg-icon></span>
      <p>未绑定，绑定后可提升账号安全性</p>
      <em @click="isPopupShow = true">绑定</em>
    </div>

    <popup-form v-show="isPopupShow && step === 1" @close="handleClose" title="手机安全验证">
      <template #form>
        <portal-form type="phone" :fnType="2" @close="handleClose" @next="handleNext" />
      </template>
    </popup-form>

    <popup-form v-show="isPopupShow && step === 2" @close="handleClose" title="绑定新邮箱">
      <template #form>
        <portal-form
          :userId="userId"
          type="email"
          :fnType="4"
          @close="handleClose"
          @ok="handleClose"
        />
      </template>
    </popup-form>
  </div>
</template>

<script>
import userMixin from "@/mixins/user-mixin.js";
import PopupForm from "@/components/popup-form/Index.vue";
import PortalForm from "@/components/portal-form/Index.vue";

export default {
  components: {
    PopupForm,
    PortalForm
  },
  mixins: [userMixin],
  data() {
    return {
      userId: "",
      isPopupShow: false,
      step: 1 // 1-手机验证  2-绑定新邮箱
    };
  },
  methods: {
    handleNext(id) {
      this.step = 2;
      this.userId = id;
    },
    handleClose() {
      this.isPopupShow = false;
      this.step = 1;
    }
  }
};
</script>

<style lang="less" scoped>
.email-cell {
  display: flex;
  align-items: center;
  > p {
    margin-right: 16px;
    color: #86909c;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  > em {
    color: #545beb;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    cursor: pointer;
  }
  .icon {
    margin-right: 8px;
  }
}
</style>
