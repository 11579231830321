<template>
  <div class="form">
    <div class="input-cell" v-if="type === 'phone'">
      <span class="input-label">手机号</span>
      <span v-if="fnType === 2" class="exist">{{ encryptPhone }}</span>
      <input
        v-if="fnType === 1"
        type="text"
        class="user-input"
        placeholder="请输入手机号"
        v-model="form.mobile"
        maxlength="11"
      />
      <span v-if="fnType === 1" class="errtip" v-show="errMsg.mobile">{{ errMsg.mobile }}</span>
    </div>
    <div class="input-cell" v-if="type === 'email'">
      <span class="input-label">邮箱</span>
      <span v-if="fnType === 3" class="exist">{{ encryptEmail }}</span>
      <input
        v-if="fnType === 4"
        type="text"
        class="user-input"
        placeholder="请输入邮箱地址"
        v-model="form.email"
      />
      <span v-if="fnType === 4" class="errtip" v-show="errMsg.email">{{ errMsg.email }}</span>
    </div>
    <div class="input-cell">
      <span class="input-label">验证码</span>
      <input
        type="text"
        class="user-input code-input"
        placeholder="请输入验证码"
        v-model="form.code"
        maxlength="6"
      />
      <span
        class="code-btn"
        :class="[disableSend ? 'code-btn-disable' : null]"
        @click="sendCode(type)"
        >{{ btnText }}</span
      >
      <span class="errtip" v-show="errMsg.code">{{ errMsg.code }}</span>
    </div>
    <div class="bottom">
      <span @click="handleOk">确定</span>
      <span @click="handleClose">取消</span>
    </div>
  </div>
</template>

<script>
import { pattern_phone, pattern_code, pattern_mail } from "@/tool/pattern.js";
import { sendCodeMsg, sendEmailCode } from "@/api/code.js";
import {
  emailVerification,
  phoneVerification,
  newPhoneVerification,
  newEmailVerification
} from "@/api/portal.js";

const encryptPhoneReg = /^(\d{3})\d{4}(\d{4})$/;
const encryptEmailReg = /(.{2}).*(@.*)/;
export default {
  props: {
    type: String,
    userId: String,
    /**
     * 1-重绑手机-校验新手机
     * 2-重绑手机-校验原手机
     * 3-重绑手机-校验邮箱
     * 4-重绑邮箱-校验新邮箱
     */
    fnType: Number
  },
  mounted() {
    if (this.fnType === 2) {
      this.form.mobile = JSON.parse(localStorage.getItem("user")).mobile;
    }
    if (this.fnType === 3) {
      this.form.email = JSON.parse(localStorage.getItem("user")).email;
    }
  },
  data() {
    return {
      form: {
        mobile: "",
        email: "",
        code: ""
      },
      errMsg: {
        mobile: "",
        email: "",
        code: ""
      },
      isValid: {
        mobile: false,
        email: false,
        code: false
      },
      btnText: "获取验证码",
      disableSend: false,
      timer: null
    };
  },
  computed: {
    encryptPhone() {
      const phone = this.form.mobile;
      return phone.replace(encryptPhoneReg, "$1****$2");
    },
    encryptEmail() {
      const email = this.form.email;
      return email.replace(encryptEmailReg, "$1******$2");
    }
  },
  watch: {
    "form.mobile"(val) {
      this.errMsg.mobile = pattern_phone.test(val) ? "" : "请输入正确的手机号";
      if (!val) {
        this.errMsg.mobile = "";
      }
      this.isValid.mobile = val && !this.errMsg.mobile;
    },
    "form.email"(val) {
      this.errMsg.email = pattern_mail.test(val) ? "" : "请输入正确的邮箱";
      if (!val) {
        this.errMsg.email = "";
      }
      this.isValid.email = val && !this.errMsg.email;
    },
    "form.code"(val) {
      this.errMsg.code = pattern_code.test(val) ? "" : "验证码为6位数字";
      if (!val) {
        this.errMsg.code = "";
      }
      this.isValid.code = val && !this.errMsg.code;
    }
  },
  methods: {
    handleClose() {
      this.$emit("close");
      if (this.fnType === 2 || this.fnType === 3) {
        this.form.code = "";
      } else {
        this.form = {};
      }
    },
    async handleOk() {
      if (this.type === "phone" && !this.form.mobile) {
        this.errMsg.mobile = "手机号不能为空";
        return;
      }
      if (this.type === "email" && !this.form.email) {
        this.errMsg.email = "邮箱不能为空";
        return;
      }
      const valid = (this.isValid.mobile || this.isValid.email) && this.isValid.code;
      if (!valid) return;
      if (this.fnType === 1) {
        const res = await newPhoneVerification({
          channel: 1,
          id: this.userId,
          ...this.form
        });
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        // 重新获取用户信息
        this.$store.dispatch("user/getUserInfo");
        this.$emit("ok");
      } else if (this.fnType === 2) {
        const res = await phoneVerification({
          ...this.form
        });
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        const newId = res.data;
        this.$emit("next", newId);
        this.form = {};
      } else if (this.fnType === 3) {
        const res = await emailVerification({
          ...this.form
        });
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        const newId = res.data;
        this.$emit("next", newId);
        this.form = {};
      } else if (this.fnType === 4) {
        const res = await newEmailVerification({
          ...this.form,
          id: this.userId
        });
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        // 重新获取用户信息
        this.$store.dispatch("user/getUserInfo");
        this.$emit("ok");
      }
    },
    async sendCode(type) {
      if (type === "phone") {
        if (!this.form.mobile) {
          this.errMsg.mobile = "手机号不能为空";
          return;
        }
        if (!this.isValid.mobile) return;
        // 发送手机短信验证
        const res = await sendCodeMsg({
          phone: this.form.mobile,
          type: 4
        });
        if (!res.success) {
          this.$message.error(res.message);
          if (res.code === 7001001) {
            const secLeft = parseInt(res.data);
            this.countDown(secLeft);
          } else {
            this.clearTime();
          }
        }
        this.countDown(60);
        return;
      } else if (type === "email") {
        if (!this.form.email) {
          this.errMsg.email = "邮箱不能为空";
          return;
        }
        if (!this.isValid.email) return;
        // 发送邮箱验证
        const res = await sendEmailCode({
          email: this.form.email,
          type: 4
        });
        if (!res.success) {
          this.$message.error(res.message);
          if (res.code === 7001001) {
            const secLeft = parseInt(res.data);
            this.countDown(secLeft);
          } else {
            this.clearTime();
          }
        }
        this.countDown(60);
        return;
      }
    },
    countDown(num) {
      this.disableSend = true;
      this.btnText = num + "s";
      let nextNum = num - 1;
      this.timer = setInterval(() => {
        if (nextNum > 0) {
          this.btnText = nextNum-- + "s";
        } else {
          this.clearTime();
        }
      }, 1000);
    },
    clearTime() {
      clearInterval(this.timer);
      this.btnText = "获取验证码";
      this.disableSend = false;
    }
  }
};
</script>

<style lang="less" scoped>
.code-btn {
  display: block;
  width: 102px;
  height: 32px;
  color: #111c34;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #e5e6eb;
  cursor: pointer;

  &.code-btn-disable {
    color: #c9cdd4;
    background-color: #f7f8fa;
    cursor: not-allowed;
  }
}
.bottom {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 36px;
  > span {
    display: block;
    width: 66px;
    height: 32px;
    margin-left: 10px;
    color: #13203c;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
  }
  :first-child {
    color: #545beb;
  }
}
.input-label {
  width: 46px;
}
.user-input {
  width: calc(100% - 22px);
}
.code-input {
  width: calc(100% - 138px);
  margin-right: 8px;
}
.errtip {
  left: 52px;
}
.exist {
  width: calc(100% - 22px);
  color: #c9cdd4;
}
</style>
